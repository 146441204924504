<template>
  <div class="register-repeat">
    <img src="@/assets/img/cg.png" alt="cg">
    <p>加入成功</p>
    <span>温馨提示：您可以前往PC端（www.gitok.com）完成企业实名认证，我们工作人员将尽快与您联系！</span>
<!--    <van-button class="btn-border-red" block  @click="$router.push('/activity')">返回原页面</van-button>-->
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.register-repeat{
    padding: 48px 36px 0;
    >img{
        width: 70px;
        display: block;
        margin: 0 auto 24px;
    }
    >p{
        font-size: 24px;
        line-height: 32px;
        color: #002626;
        text-align: center;
        margin-bottom: 12px;
    }
    >span{
        display: block;
        font-size: 16px;
        line-height: 24px;
        color: #666;
        text-align: center;
        margin-bottom: 48px;
    }
}
</style>
